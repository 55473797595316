.titulo {
    font-family: 'Cormorant Garamond', serif;
}
.cursor-imagen{
    cursor: crosshair ;
}
.cursor-texto{
    cursor: default ;
}
.parrafo{
    font-family: 'trajan', serif;
}