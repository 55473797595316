.estudio {
  position: relative;
  background: #fff;
  border-radius: 20px;
  overflow: hidden
}
.correccion {
  cursor: default;
  height: 100%;
}
.estudio-1:before {
  content: ' ';
  background: #22007015;
  z-index: 5;
  height: 180px;
  position: absolute;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
  width: 1000px;
  top: -120px;
  left: -210px
}

.estudio-1:after {
  content: ' ';
  right: 0;
  left: 0;
  top: 0;
  z-index: 5;
  height: 180px;
  position: absolute;
  -webkit-transform: rotate(106deg);
  -ms-transform: rotate(106deg);
  transform: rotate(106deg);
  width: 1000px;
  top: -210px;
  left: -470px;
  background: #00377120
}

