.vertical-text {
    cursor: default;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
}

.v-center {
    align-items: center;
    justify-content: center;
    display: flex;
}